/***************************************************************************
 *
 * SCRIPT JS
 *
 ***************************************************************************/
// CoreUI
require('@coreui/coreui');
$(document).ready(function() {
    var year = (new Date).getFullYear();
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
        if (ua.indexOf('chrome') > -1) {
            // alert("1") // Chrome
        } else {
            $('.warpPass .styleInput').addClass('safari');
        }
    }

    var window_w = $(window).width();
    var window_h = $(window).height()
    if ($(window).width() <= 768) {
        $('body').addClass(getMobileOperatingSystem());
    }
    //Click event to scroll to top
    $('.scrollToTop').on('click', function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });


    //SCROLL TO TOP 
    $(window).scroll(function() {
        if ($(this).scrollTop() > 80) {
            $('.scrollToTop').fadeIn(400);
        } else {
            $('.scrollToTop').fadeOut(400);
        }
    });

    // SCROLL ANCHOR
    $('.anchor a[href*="#"]:not([href="#"])').on('click', function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                var target_offset = 0;
                if (target.attr('data-offset')) {
                    target_offset = target.attr('data-offset');
                    target_offset = parseInt(target_offset);
                }
                $('html,body').animate({
                    scrollTop: target.offset().top - 90 + target_offset
                }, 1000);
                return false;
            }
        }
    });
    
    $('.mainMenu.logged .menu li a').on('click',function(){
        console.log(1);
        $('.mainMenu.logged .subMenu').stop().slideToggle();
    })
    $('#content').on('click', function(){
        $('.mainMenu.logged .subMenu').slideUp();
    })
    if(window_w <= 768){
        $('.close-sub-menu').on('click',function(){
            e.preventDefault();
            $('.mainMenu.logged .subMenu').toggleClass('close');
        })
    }

    $('.eye').click(function(){
        console.log('1')
        let self = $(this);
        let boxInput = self.closest('.boxInput');
        let input = boxInput.find('.styleInput');
        let iconEye = boxInput.find('.eye')
        if(input.val() === '') return false
        if (input.prop("type") === "password" && iconEye.hasClass('hide')) {
            input.prop("type", "text");
            iconEye.removeClass('hide')
            } else {
            input.prop("type", "password");
            iconEye.addClass('hide')
        }
    })

    $('.showPopup').click(function(){
        $('#popup').removeClass('hide');
    })
    $('.popup-form .close, .popup-form .cancel').click(function(){
        $(this).closest('.popup-form').addClass('hide')
    })
    // $('.areaSidebarLeft a').on('click',function(e){
    //     e.preventDefault();
    //     let self = $(this);
    //     let sidebar = self.closest('.sidebar');
    //     let dataTag = self.attr('href');
    //     if(!self.parents('li').hasClass('action')){
    //         sidebar.find('li').removeClass('active');
    //         self.parents('li').addClass('active');
    //         !$('.sidebar-right').hasClass('open') && $('.sidebar-right').addClass('open');
    //         if(window_w <= 768) {
    //             sidebar.find('.action a .img').attr('src','assets/images/common/icon-back-sp.png').addClass('back');
    //             if(sidebar.find('li').hasClass('.active') || sidebar.find('li').hasClass('.action')){
    //                 sidebar.find('li').css('display','none')
    //             }
    //         }
    //     } else {
    //         $('.sidebar-right').toggleClass('close');
    //         sidebar.find('li').removeClass('active');
    //         $('.sidebar-right').removeClass('open')
    //         self.find('.img').hasClass('back') && self.find('.img').removeClass('back').attr('src','assets/images/common/icon-more-sp.png');
    //     }
        
    // })
    // $.datepicker.regional['ja'] = {
    //     closeText: '閉じる',
    //     prevText: '&#x3c;前',
    //     nextText: '次&#x3e;',
    //     currentText: '今日',
    //     monthNames: ['1月','2月','3月','4月','5月','6月',
    //     '7月','8月','9月','10月','11月','12月'],
    //     monthNamesShort: ['1月','2月','3月','4月','5月','6月',
    //     '7月','8月','9月','10月','11月','12月'],
    //     dayNames: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
    //     dayNamesShort: ['日','月','火','水','木','金','土'],
    //     dayNamesMin: ['日','月','火','水','木','金','土'],
    //     weekHeader: '週',
    //     dateFormat: 'dd/mm/yy',
    //     firstDay: 0,
    //     isRTL: false,
    //     showMonthAfterYear: true,
    //     yearSuffix: '年'};
    // $.datepicker.setDefaults($.datepicker.regional['ja']);
    $.validator.addMethod("languageKana", function(value) {
        regEx = /^[ァ-ン]+$/;
        if(!regEx.test(value))
            return false;
        else if(regEx.test(value))
            return true;
    }, '名前のカナ形式が無効です。');
    
    $("#txtDate").datepicker({
        // showOn: 'button',
        buttonImageOnly: true,
        // buttonImage: 'assets/images/common/icon-calendar2x.png',
        dateFormat: 'dd/mm/yy',
        changeYear: true,
        maxDate: new Date(year, 11, 31),
        yearRange : 'c-65:c+10',
        onSelect: function () {
            $("#txtDate").valid();
        }
    });
});

// DETECH ANDROID OR IOS
getMobileOperatingSystem();
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}